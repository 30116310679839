import axios from "axios";

export const getContracts = async () => {
  const res = await axios.get(
    `${process.env.REACT_APP_DEV_HOST}/getContracts.php`,
    {
      /* Decomentam daca adaugam filtre la request*/
    }
  );
  return res.data;
};

export const generatePV = async (id) => {
  const res = await axios.get(
    `${process.env.REACT_APP_DEV_HOST}/generatepv.php?id=${id}`
  );
  return res;
};
