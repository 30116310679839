import React, { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { generatePV } from "../Api/Dashboard";
import Loading from "./Loading";

const Generate = () => {
  const { id } = useParams();
  const [baseString, setBaseString] = useState();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    generatePV(id).then((res) => {
      setBaseString(res.data);
      setLoading(false);
    });
  }, []);

  const downloadPDF = () => {
    const linkSource = "data:application/pdf;base64," + baseString;
    const byteCharacters = atob(baseString);
    const byteArrays = [];
    const contentType = "";
    const sliceSize = 512;

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    const fileName = "proces_verbal_" + id + ".pdf";
    const link = document.createElement("a");
    // create a blobURI pointing to our Blob
    link.href = URL.createObjectURL(blob);
    link.download = fileName;
    // some browser needs the anchor to be in the doc
    document.body.append(link);
    setTimeout(() => {
      link.click();
      link.remove();
      // in case the Blob uses a lot of memory
      setTimeout(() => URL.revokeObjectURL(link.href), 7000);
    }, 200);

    // setTimeout(() => {
    //   // ***
    //   downloadLink.click(); // *** You *might* need to put another `setTimeout` around this, but I don't think so
    // }, 200);

    // // const linkSource = "data:application/pdf;base64," + baseString;
    // // window.open(linkSource, "_blank");
  };

  return (
    <>
      {loading == true ? (
        <Loading />
      ) : (
        <>
          <div className="md:hidden text-center mt-10 mb-6">
            {/* <a
              href={"data:application/octet-stream;base64," + baseString}
              download={"proces_verbal_" + id + ".pdf"}
            > */}
            <button
              className="bg-blue-400 text-white px-2 py-1 rounded-md text-xl"
              onClick={(e) => {
                e.preventDefault();
                downloadPDF();
              }}
            >
              DESCARCA2
            </button>
            {/* </a> */}
          </div>
          <div className="w-screen h-screen">
            <iframe
              src={"data:application/pdf;base64," + baseString}
              width="100%"
              height="100%"
            ></iframe>
          </div>
        </>
      )}
    </>
  );
};

export default Generate;
