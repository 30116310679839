import React from "react";
import logo from "./logo.svg";
import "./App.css";
import Dashboard from "./Pages/Dashboard";
import { Route, Routes } from "react-router-dom";
import Generate from "./Pages/Generate";

function App() {
  return (
    <Routes>
      <Route path="/" exact element={<Dashboard />} />
      <Route path="/generate/:id" exact element={<Generate />} />
    </Routes>
  );
}

export default App;
