import React, { useEffect, useState } from "react";
import { generatePV, getContracts } from "../Api/Dashboard";
import PVModal from "./PVModal";
import { Link } from "react-router-dom";

const Dashboard = () => {
  const [contracts, setContracts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pdfUrl, setPdfUrl] = useState("");
  useEffect(() => {
    getContracts().then((res) => {
      setContracts(res);
      setLoading(false);
    });
  }, []);

  const arrayBufferToBase64 = (arrayBuffer) => {
    const uint8Array = new Uint8Array(arrayBuffer);
    const binaryString = uint8Array.reduce(
      (acc, byte) => acc + String.fromCharCode(byte),
      ""
    );
    return btoa(binaryString);
  };
  const [base64P, setBase64P] = useState("");

  const genPV = (id) => {
    generatePV(id)
      .then((response) => {
        console.log(response);
        setBase64P(response.data);
        setOpen(true);
      })
      .catch((error) => {
        console.error("There was a problem with the fetch operation:", error);
      });
  };
  const [open, setOpen] = useState(true);

  return (
    <>
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-base font-semibold leading-6 text-gray-900">
              Contracte
            </h1>
            <p className="mt-2 text-sm text-gray-700">
              O lista cu contractele ce sunt dupa data de 01.11.2023.
            </p>
          </div>
          <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
            <button
              type="button"
              className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Add user
            </button>
          </div>
        </div>
        <div className="-mx-4 mt-8 sm:-mx-0">
          <table className="min-w-full divide-y divide-gray-300">
            <thead>
              <tr>
                <th
                  scope="col"
                  className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                >
                  Nr. contract
                </th>
                <th
                  scope="col"
                  className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
                >
                  Nume
                </th>
                <th
                  scope="col"
                  className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
                >
                  Data
                </th>
                <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
                  <span className="sr-only">Edit</span>
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white">
              {contracts
                .sort((a, b) => {
                  return parseInt(b.ID) - parseInt(a.ID);
                })
                .map((contract) => (
                  <tr key={contract.ID}>
                    <td className="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none sm:pl-0">
                      {contract.ID}
                      <dl className="font-normal lg:hidden">
                        <dt className="sr-only">Nume</dt>
                        <dd className="mt-1 truncate text-gray-700">
                          {contract.contract_name}
                        </dd>
                        <dt className="sr-only sm:hidden">Data</dt>
                        <dd className="mt-1 truncate text-gray-500 sm:hidden">
                          {contract.contract_date}
                        </dd>
                      </dl>
                    </td>
                    <td className="hidden px-3 py-4 text-sm text-gray-500 lg:table-cell">
                      {contract.contract_name}
                    </td>
                    <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
                      {contract.contract_date}
                    </td>

                    <td className="py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                      <Link to={"/generate/" + contract.ID}>
                        <button className="text-indigo-600 hover:text-indigo-900">
                          Genereaza
                        </button>
                      </Link>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
